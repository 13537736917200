<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2>Alerts</h2>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 col-sm-12">
        <div
          class="card justify-content-start p-60px"
          :class="[height_card < 550 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="row">
            <div class="col-12 col-sm-12">
              <hr class="h-3" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12">
              <table class="table table-light table-hover">
                <thead>
                  <tr>
                    <th class="pl-1">Message</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(alert, index) in alerts" :key="index">
                    <td
                      :class="{ 'color-blue font-bold': alert.read == false }"
                      class="pl-1"
                    >
                      {{ alert.message }}
                    </td>
                    <td
                      :class="{ 'color-blue font-bold': alert.read == false }"
                    >
                      {{ alert.type }}
                    </td>
                    <td
                      :class="{ 'color-blue font-bold': alert.read == false }"
                    >
                      {{ alert.date }}
                    </td>
                    <td
                      :class="{ 'color-blue font-bold': alert.read == false }"
                      class="text-center"
                    >
                      <i
                        class="fas fa-times-circle"
                        @click="deleteAlert(index)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      height_card: 0,
      alerts: []
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getData();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    getData() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/alerts`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.length === 0) {
            self.alerts = response.data;
          } else {
            self.alerts = [
              {
                message: "Nullam porta sapien ac ultrices suscipit. ",
                type: "Notification",
                date: "Jun 23, 2020 - 11:23 h",
                read: false
              },
              {
                message: "Quisque libero velit, aliquam sit amet tellus at.",
                type: "Notification",
                date: "Jun 23, 2020 - 11:23 h",
                read: true
              },
              {
                message: "Donec tristique molestie eros.",
                type: "Notification",
                date: "Jun 23, 2020 - 11:23 h",
                read: true
              }
            ];
          }
          self.calculateHeight;
          self.loading = false;
        })
        .catch(error => (console.log(error), alert(error)));
    },
    deleteAlert(index) {
      this.alerts.splice(index, 1);
    }
  }
};
</script>
